import React from "react";


function Contact() {

  return (
    <div id="kontakt" className=" mt-20 md:mt-10 ">
      <h1
        data-aos="fade-up"
        data-aos-duration="1500"
        className="text-2xl md:text-3xl text-palette font-extrabold text-center "
      >
        Kontakt
      </h1>

      <div
        data-aos="fade-up"
        data-aos-duration="1500"
        className="sm:grid grid-cols-3"
      >
        <div></div>
        <div className="text-center col-span-1 border-b-2 border-palette mt-4 ml-8 md:ml-24 mr-8 md:mr-24 mb-5 md:mb-10 "></div>
      </div>
      <div className=" md:grid grid-cols-2 ml-8 lg:ml-24 mr-8 lg:mr-4">
        <div
          data-aos="fade-right"
          data-aos-duration="1200"
          className=" col-span-1 hidden md:block   mb-20"
        >
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3209.6585721454035!2d8.67178161710531!3d47.43163078562053!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479aa37062862a5d%3A0x66cdeeb6da5add93!2sSB%20BAU%20GROUP%20AG!5e0!3m2!1smk!2smk!4v1699493458824!5m2!1smk!2smk"
            width="100%"
            height="450"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <div className=" md:grid grid-cols-2 md:ml-4 ">
          <div data-aos="fade-up" data-aos-duration="1200" className="">
            <h1 className="text-palette md:text-xl font-bold"> Telefonnummer</h1>
            <div className="text-palette mt-2 md:mt-4 mb-2 md:mb-0 border-b-2 md:mr-16 border-palette font-semibold ">
              <a href="tel:0447422828" className="hover:text-white">
                044 742 28 28 &nbsp;
              </a>
              /
              <a href="tel:+41766122244" className="hover:text-white">
                &nbsp; 076 612 22 44
              </a>
            </div>
          </div>
          <div data-aos="fade-up" data-aos-duration="1200" className="">
            <h1 className="text-palette text-lg md:text-xl mt-3 md:mt-0 font-bold">
              {" "}
              Allgemeine E-Mail
            </h1>
            <p className="text-palette font-semibold mt-4 mb-2 md:mb-0 border-b-2 md:mr-20 border-palette ">
              Info@sb-group.ch{" "}
            </p>
          </div>
          <div data-aos="fade-up" data-aos-duration="1200" className="">
            <h1 className="text-palette text-lg md:text-xl  mt-3 md:mt-0 font-bold"> Adresse</h1>
            <div className="text-palette font-semibold  mt-2 md:mt-4 mb-2 md:mb-0 border-b-2 border-palette md:mr-16 ">
              <a
                href="https://www.google.ch/maps/place/SB+BAU+GROUP+AG/@47.4319171,8.6715705,17z/data=!4m5!3m4!1s0x479aa37062862a5d:0x66cdeeb6da5add93!8m2!3d47.4319135!4d8.6737592"
                target="{_blank}"
                className="hover:text-white"
              >
                Ringstrasse 26 8317 Tagelswangen
              </a>
            </div>
          </div>
          <div data-aos="fade-up" data-aos-duration="1200" className="">
            <h1 className="text-palette text-lg md:text-xl mt-3 md:mt-0 font-bold"> Arbeitszeit</h1>
            <p className="text-palette font-semibold  mt-2 md:mt-4 mb-2 md:mb-0 border-b-2 border-palette md:mr-20 ">
              Mo. - Fr.: 07:00 - 17:00 Uhr <br />
              Samstag: 08:00 - 12:00 Uhr{" "}
            </p>
          </div>
          <a
            data-aos="fade-left"
            data-aos-duration="1200"
            href="mailto:info@sb-group.ch"
            className="hidden text-center text-white font-bold lg:text-lg   hover:text-white border border-palette hover:bg-palette mr-20  mb-28 md:flex justify-center items-center"
          >
            Vereinbaren Sie einen Termin
          </a>
        </div>
        <a
          data-aos="fade-up"
          data-aos-duration="1000"
          href="mailto:info@sb-group.ch"
          className="block md:hidden text-center text-white font-bold text-base bg-background hover:bg-default hover:text-white border border-palette   py-2 px-4 mt-5   mr-8 ml-8 mb-8 "
        >
          Vereinbaren Sie einen Termin
        </a>
        <div
          data-aos="fade-up"
          data-aos-duration="1200"
          className=" col-span-1 block md:hidden  h-96 mb-20"
        >
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3209.6585721454035!2d8.67178161710531!3d47.43163078562053!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479aa37062862a5d%3A0x66cdeeb6da5add93!2sSB%20BAU%20GROUP%20AG!5e0!3m2!1smk!2smk!4v1699493458824!5m2!1smk!2smk"
            width="100%"
            height="450"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
}
export default Contact;
