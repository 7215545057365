import React, { useEffect, useState } from "react";
import "./Refer.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import DataR from "../../json/DataR";
import { FreeMode, Pagination, Autoplay } from "swiper/modules";

function Refer() {
  const [slidesPerView, setSlidesPerView] = useState(4);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setSlidesPerView(2);
      } else {
        setSlidesPerView(4);
      }
    };

    // Set initial slidesPerView
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div id="uns" className="md:mt-10 ">
      <h1
        data-aos="fade-up"
        data-aos-duration="1500"
        className="text-2xl md:text-3xl text-palette font-extrabold text-center "
      >
        Referenzen
      </h1>

      {/* <div
        data-aos="fade-up"
        data-aos-duration="1500"
        className="sm:grid grid-cols-3"
      >
        <div></div>
        <div className="text-center col-span-1 border-b-2 border-palette mt-4 ml-8 md:ml-24 mr-8 md:mr-24 mb-5 md:mb-10 "></div>
      </div> */}
      <div
        data-aos="fade-up"
        data-aos-duration="1500"
        className="mt-10 md:mt-10 mb-10 md:mb-20 ml-8 lg:ml-24 mr-8 lg:mr-24 border-y-2 border-palette"
      >
        <Swiper
          slidesPerView={slidesPerView}
          spaceBetween={30}
          freeMode={true}
          autoplay={{
            delay: 2500, // Match the progress bar animation duration
            disableOnInteraction: false,
          }}
          modules={[FreeMode, Pagination, Autoplay]}
          className="swiper6"
        >
          {DataR.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="flex justify-center mt-2 mb-2">
                <a href={item.link} target="_blank" rel="noopener noreferrer">
                  <img
                    className="h-full w-full bg-cover"
                    src={item.image}
                    alt={`item-${index}`}
                  />
                </a>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default Refer;
