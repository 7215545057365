import {  useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import BL3 from "./../../media/Bodenbeläge/BL3.jpeg";
import GE13 from "./../../media/Gebäudereinigung/GE13.jpeg";
import ML1 from "./../../media/Malerarbeiten/ML6.jpeg"
import HA1 from "./../../media/Hauswartung/HA1.jpeg"

function Services() {
  useEffect(() => {
    AOS.init();
  }, []);
  function handleCardClick(path) {
    localStorage.setItem("selectedCardPath", path);
  }
  return (
    <div className=" ">
      <h1
        data-aos="fade-up"
        data-aos-duration="1500"
        className=" mt-10 md:mt-32 text-2xl md:text-3xl text-palette font-extrabold text-center "
      >
        Unserse Dienstleistungen

      </h1>

      <div
        data-aos="fade-up"
        data-aos-duration="1500"
        className="sm:grid grid-cols-3"
      >
        <div></div>
        <div className="text-center col-span-1 border-b-2 border-palette mt-4 ml-8 lg:ml-10 mr-8 lg:mr-10 mb-10 "></div>
      </div>
      <div className="flex justify-center">
      <div className="sm:grid grid-cols-4 ml-0 lg:ml-24 mr-0  lg:mr-24 mb-10 ">
        {/* //Bodenbeläge */}
        <div
          data-aos="fade-right"
          data-aos-duration="2000"
          className="md:relative max-w-md   bg-palette  border-solid shadow-xl sm:ml-4 ml-14 sm:mr-4 mr-14 sm:mb-12 mb-16  sm:hover:scale-105"
          onClick={() => handleCardClick("/bodenbeläge")}
        >
          <a href="/bodenbeläge">
            <img
              className=" h-64 md:h-128 w-full object-cover object-center"
              src={BL3}
              alt="Bodenbeläge"
            />
          </a>
          <div className="p-5">
            <a href="/bodenbeläge">
              <h5 className="mb-2 text-xl lg:text-2xl text-center text-white tracking-tight">
              Bodenbeläge
              </h5>
            </a>
          </div>
          <a href="/bodenbeläge">
            <div
              class="sm:block hidden  md:absolute md:inset-0 bg-opacity-80  bg-palette   opacity-0 transition-opacity duration-300 hover:opacity-100"
              onClick={() => handleCardClick("/bodenbeläge")}
            >
              <h5 class=" lg:text-4xl text-center text-white mt-52  tracking-tight ">
              Bodenbeläge
              </h5>
            </div>
          </a>
        </div>
        {/* Malerarbeiten Card */}
        <div
          data-aos="fade-up"
          data-aos-duration="2000"
          class="md:relative max-w-md   bg-palette border-solid shadow-xl sm:ml-4 ml-14 sm:mr-4 mr-14  sm:mb-12 mb-16  sm:hover:scale-105 "
          onClick={() => handleCardClick("/malerarbeiten")}
        >
          <a href="/malerarbeiten">
            <img
              className=" h-64 md:h-128 object-cover object-center w-full"
              src={ML1}
              alt="Malerarbeiten"
            />
          </a>
          <div class="p-5">
            <a href="/malerarbeiten">
              <h5 class="mb-2 text-xl lg:text-2xl text-center text-white   tracking-tight ">
              Malerarbeiten
              </h5>
            </a>
          </div>
          <a href="/malerarbeiten">
            <div
              class="sm:block hidden  absolute inset-0 bg-opacity-80  bg-palette   opacity-0 transition-opacity duration-300 hover:opacity-100"
              onClick={() => handleCardClick("/malerarbeiten")}
            >
              <h5 class=" lg:text-4xl text-center text-white mt-52   tracking-tight ">
              Malerarbeiten
              </h5>
            </div>
          </a>
        </div>
        {/* Gebäudereinigung Card */}
         <div
          data-aos="fade-up"
          data-aos-duration="2000"
          class="md:relative max-w-md   bg-palette border-solid shadow-xl sm:ml-4 ml-14 sm:mr-4 mr-14  sm:mb-12 mb-16  sm:hover:scale-105 "
          onClick={() => handleCardClick("/gebäudereinigung")}
        >
          <a href="/gebäudereinigung">
            <img
              class=" h-64 md:h-128 object-cover object-center w-full"
              src={GE13}
              alt="Gebäudereinigung"
            />
          </a>
          <div class="p-5">
            <a href="/gebäudereinigung">
              <h5 class="mb-2 text-xl lg:text-2xl text-center text-white   tracking-tight ">
              Gebäudereinigung
              </h5>
            </a>
          </div>
          <a href="/gebäudereinigung">
            <div
              class="sm:block hidden  absolute inset-0 bg-opacity-80  bg-palette   opacity-0 transition-opacity duration-300 hover:opacity-100"
              onClick={() => handleCardClick("/gebäudereinigung")}
            >
              <h5 class=" lg:text-3xl text-center text-white mt-52   tracking-tight ">
              Gebäudereinigung
              </h5>
            </div>
          </a>
        </div> 
          {/* Hauswartung Card */}
          <div
          data-aos="fade-left"
          data-aos-duration="2000"
          class="md:relative max-w-md   bg-palette border-solid shadow-xl sm:ml-4 ml-14 sm:mr-4 mr-14  sm:mb-12 mb-16  sm:hover:scale-105 "
          onClick={() => handleCardClick("/hauswartung")}
        >
          <a href="/hauswartung">
            <img
              class=" h-64 md:h-128 object-cover object-center w-full"
              src={HA1}
              alt="Hauswartung"
            />
          </a>
          <div class="p-5">
            <a href="/hauswartung">
              <h5 class="mb-2 text-xl lg:text-2xl text-center text-white   tracking-tight ">
              Hauswartung
              </h5>
            </a>
          </div>
          <a href="/hauswartung">
            <div
              class="sm:block hidden  absolute inset-0 bg-opacity-80  bg-palette   opacity-0 transition-opacity duration-300 hover:opacity-100"
              onClick={() => handleCardClick("/hauswartung")}
            >
              <h5 class=" lg:text-4xl text-center text-white mt-52   tracking-tight ">
              Hauswartung
              </h5>
            </div>
          </a>
        </div> 
      </div>
      
      </div>
    </div>
  );
}
export default Services;
