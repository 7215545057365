import sand from "./../media/Refer/Sandbox.png"
import phoenix from "./../media/Refer/phoenix.png"
import burgring from "./../media/Refer/burgring.png"
import bgw from "./../media/Refer/bgw.png"
import vettleri from "./../media/Refer/vettleri.png"
import emil from "./../media/Refer/emil.png"
import fischer from "./../media/Refer/fischer.png"
import sw from "./../media/Refer/sw.png"
import hitachi from "./../media/Refer/hitachi.png"

const DataR = [
  {
    image:sand ,
    link: 'https://sandboxprojects.ch/de/',  // Replace with the actual link for sand

  },
  {
    image: phoenix,
    link: 'https://www.phoenixcontact.com/de-ch/',  // Replace with the actual link for sand

  },
  {
    image: burgring,
    link: 'https://burgring-zo.ch/unternehmen/kooperationen/burgring-ag-immobilien',  // Replace with the actual link for sand

  },
  {
    image:bgw,
    link: 'https://www.bg-werdmuehle.ch/home.html',  // Replace with the actual link for sand

  },
  {
    image: vettleri,
    link: 'https://www.vetterli-partner.ch/',  // Replace with the actual link for sand

  },
  {
    image: emil,
    link: 'https://www.emilfrey.ch/de',  // Replace with the actual link for sand

  },
  {
    image: fischer,
    link: 'https://www.fischer-architekten.ch/de',  // Replace with the actual link for sand

  },
  {
    image: sw,
    link: 'https://www.sw-bautreuhand.ch/',  // Replace with the actual link for sand

  },
  {
    image: hitachi,
    link: 'https://www.hitachienergy.com/ch/',  // Replace with the actual link for sand

  },
];
export default DataR;
