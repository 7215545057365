import HA1 from "./../../media/Hauswartung/HA1.jpeg"
import HA2 from "./../../media/Hauswartung/HA2.jpeg"
import HA3 from "./../../media/Hauswartung/HA3.jpeg"
import HA4 from "./../../media/Hauswartung/HA4.jpeg"
import HA5 from "./../../media/Hauswartung/HA5.jpeg"
import HA6 from "./../../media/Hauswartung/HA6.jpeg"
import HA7 from "./../../media/Hauswartung/HA7.jpeg"
import HA8 from "./../../media/Hauswartung/HA8.jpeg"
import HA9 from "./../../media/Hauswartung/HA9.jpeg"
import HA10 from "./../../media/Hauswartung/HA10.jpeg"
import HA11 from "./../../media/Hauswartung/HA11.jpeg"
import HA12 from "./../../media/Hauswartung/HA12.jpeg"
import HA13 from "./../../media/Hauswartung/HA13.jpeg"


const DataH =[
  // {
  //     category: "Hauswartung",
  //     image: HA1,
  //     title: "Card 1",
  //     // additional_images: [
  //     //   B2,
  //     //   B3,
  //     //   B4,
  //     //   B2,
  //     //   B3,
  //     //   B9,
  //     //   B2,
  //     //   B3,
  //     //   B9,
  //     //   B10,
  //     //   B2,
  //     //   B3
  //     // ]
    
  // },
  {
    category: "Hauswartung",
    image: HA1,
    title: "Hauswartung",
  },
  {
    category: "Hauswartung",
    image: HA2,
    title: "Hauswartung",
  },
  {
    category: "Hauswartung",
    image: HA3,
    title: "Hauswartung",
  },
  {
    category: "Hauswartung",
    image: HA4,
    title: "Hauswartung",
  },
  {
    category: "Hauswartung",
    image: HA5,
    title: "Hauswartung",
  },
  {
    category: "Hauswartung",
    image: HA6,
    title: "Hauswartung",
  },
  {
    category: "Hauswartung",
    image: HA7,
    title: "Hauswartung",
  },
  {
    category: "Hauswartung",
    image: HA8,
    title: "Hauswartung",
  },
  {
    category: "Hauswartung",
    image: HA9,
    title: "Hauswartung",
  },
  {
    category: "Hauswartung",
    image: HA10,
    title: "Hauswartung",
  },
  {
    category: "Hauswartung",
    image: HA11,
    title: "Hauswartung",
  },
  {
    category: "Hauswartung",
    image: HA12,
    title: "Hauswartung",
  },
  {
    category: "Hauswartung",
    image: HA13,
    title: "Hauswartung",
  },




]

export default DataH;