import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import DataB from "../../json/Bodenbeläge/DataB";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-fade";

import "./HeaderBa.css";
// import required modules
import { Autoplay, EffectFade } from "swiper/modules";
function HeaderBa() {
  return (
    <div className=" md:mt-10 md:mb-10 ">
      <div className=" md:grid grid-cols-2 ">
        <div
          data-aos="fade-left"
          data-aos-duration="1200"
          className="block md:hidden col-span-1 "
        >
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            effect={"fade"}
            autoplay={{
              delay: 2500, // Match the progress bar animation duration
              disableOnInteraction: true,
            }}
            modules={[Autoplay, EffectFade]}
            className="mySwiper3 "
          >
            {DataB.map((item, index) => (
              <SwiperSlide key={index}>
                <img src={item.image} alt={item.title} />
                <div className="absolute abstract-shapeS bottom-0 md:bottom-0 ">
                <p className="text-white  text-base md:text-xl  font-bold mx-auto">
                  {item.title}
                </p>
              </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className=" col-span-1 ml-8 lg:ml-24 mr-8 lg:mr-24">
          <div
            data-aos="fade-right"
            data-aos-duration="1200"
            className="mt-10 md:mt-10"
          >
            <div className=" mx-auto ">
              <h1 className="text-3xl lg:text-5xl text-palette  font-bold mt-0 md:mt-20 mb-3 md:mb-6">
                Bodenbeläge{" "}
              </h1>
              <p className="text-palette md:pr-20 text-base  lg:text-xl mt-0 md:mt-10  mb-4 md:mb-0">
                Wir stellen die gängigsten Bodenbeläge vor und sagen, welcher
                Fußbodenbelag für wen geeignet ist. Von robusten Fliesen über
                edles Parkett bis hin zu trendigem Vinylboden. Am Ende
                entscheiden Sie, was Ihnen unter die Füße kommt!
                <ul className=" text-paletteof list-disc text-base ml-5 mt-2 font-semibold ">
                  <li>Böden verlegen wie Parkett / Laminat / Linoleum / Kork etc.</li>
                  <li>Schmutzschleusen & Textile Bodenbeläge.</li>
                  <li>Schleifen / versiegeln / ölen / polieren.</li>
                  <li>Nachpflegen / auffrischen.</li>
                  <li>Instandhaltung / Unterhalt & Reparaturen / Sockelleisten Montage.</li>
                </ul>
              </p>
            </div>
          </div>
        </div>
        <div
          data-aos="fade-left"
          data-aos-duration="1200"
          className="col-span-1 hidden md:block ml-8 lg:ml-24 mr-8 lg:mr-24 lg:-mt-10"
        >
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            effect="fade"
            autoplay={{
              delay: 2500,
              disableOnInteraction: true,
            }}
            modules={[Autoplay, EffectFade]}
            className="mySwiper"
          >
            {DataB.map((item, index) => {
              console.log("item.image:", item.image); // Log the image URL

              return (
                <SwiperSlide key={index}>
                  <img src={item.image} alt={item.title} />
                  <div className="absolute abstract-shapeS bottom-0 md:bottom-0 ">
                <p className="text-white  text-base md:text-xl  font-bold mx-auto">
                  {item.title}
                </p>
              </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </div>
  );
}
export default HeaderBa;
