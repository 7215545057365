import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import DataS from "../../json/DataS";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-fade";

import "./Hero.css";
// import required modules
import { Autoplay, EffectFade } from "swiper/modules";
function Header() {
  return (
    <div className=" md:mt-10 md:mb-10 ">
      <div className=" md:grid grid-cols-2 ">
        <div
          data-aos="fade-left"
          data-aos-duration="1200"
          className="block md:hidden col-span-1"
        >
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            effect={"fade"}
            autoplay={{
              delay: 2500, // Match the progress bar animation duration
              disableOnInteraction: true,
            }}
            modules={[Autoplay, EffectFade]}
            className="mySwiper3"
          >
            {DataS.map((item, index) => (
              <SwiperSlide key={index}>
                <img src={item.image} alt={item.title} />
                <div className="absolute abstract-shapeS bottom-0 md:bottom-0 ">
                  <p className="text-white  text-base md:text-xl  font-bold mx-auto">
                    {item.title}
                  </p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className=" col-span-1 ml-6 md:ml-24 mr-6 md:mr-24">
          <div
            data-aos="fade-right"
            data-aos-duration="1200"
            className="mt-10 md:mt-20"
          >
            <h1 className="text-2xl lg:text-3xl  text-left text-palette font-black mb-3 md:mb-6">
              SB Bau Group AG
            </h1>
            <p className="text-palette font-bold text-sm  lg:text-lg md:mr-20 mb-4 md:mb-0">
              Die SB Bau Group AG zeichnet sich durch hohe Flexibilität und
              technisch einwandfreie Ausführung aus. Unsere kosteneffizienten
              Dienstleistungen werden genau auf Ihre Anforderungen
              zugeschnitten. Mit einem motivierten Team bieten wir umfassende
              Beratung und Unterstützung. Unser Leistungsspektrum umfasst
              Bodenbeläge, Malerarbeiten, Unterhaltsreinigungen und
              spezialisierte Dienstleistungen mit 24-Stunden-Notdienst.
              Entdecken Sie Exzellenz in jedem Aspekt unserer Dienstleistungen
              bei der SB Bau Group AG.
            </p>
          </div>
        </div>
        <div
          data-aos="fade-left"
          data-aos-duration="1200"
          className=" col-span-1 hidden md:block lg:-mt-12 lg:-ml-24 lg:mr-24 "
        >
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            effect={"fade"}
            autoplay={{
              delay: 2500, // Match the progress bar animation duration
              disableOnInteraction: true,
            }}
            modules={[Autoplay, EffectFade]}
            className="mySwiper"
          >
            {DataS.map((item, index) => (
              <SwiperSlide key={index}>
                <img src={item.image} alt={item.title} />
                <div className="absolute abstract-shapeS bottom-0 md:bottom-0 ">
                  <p className="text-white  text-base md:text-xl  font-bold mx-auto">
                    {item.title}
                  </p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
}
export default Header;
