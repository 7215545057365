import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom"; // Import BrowserRouter
import Home from "./components/home/Home";
import Boden from "./components/Bodenbeläge/Bodenbeläge";
import Gebäudereinigung from "./components/Gebäudereinigung/Gebäudereinigung";
import Malerarbeiten from "./components/Malerarbeiten/Malerarbeiten";
import Hauswartung from "./components/Hauswartung/Hauswartung";
function App() {
  return (
    <BrowserRouter>
      {/* Wrap your Routes with BrowserRouter */}
      <div className=" h-full w-full overflow-x-hidden ">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/bodenbeläge" element={<Boden />} />
          <Route path="/malerarbeiten" element={<Malerarbeiten />} />
          <Route path="/gebäudereinigung" element={<Gebäudereinigung />} />
          <Route path="/malerarbeiten" element={<Malerarbeiten />} />
          <Route path="/hauswartung" element={<Hauswartung />} />

        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
